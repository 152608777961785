import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

export default defineNuxtPlugin(() => {

  const firebaseConfig = {
    apiKey: "AIzaSyA0d6e57CLQZ-5RY55XWjPEaWA9NuskmfI",
    authDomain: "employwell-web-app.firebaseapp.com",
    projectId: "employwell-web-app",
    storageBucket: "employwell-web-app.appspot.com",
    messagingSenderId: "288239708297",
    appId: "1:288239708297:web:3991154a1e41255e0d3e58",
    measurementId: "G-T09S3EG62T"
  }

  const app = initializeApp(firebaseConfig)
  const db = getFirestore(app)

  return {
    provide: {
      firebaseApp: app,
      db,
    },
  }

})